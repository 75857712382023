<script>
import TheHeader from '../components/shared/Header'
import TheFooter from '../components/shared/Footer'
import QuestionnaireRoot from "@/components/quotes-questionnaire/QuestionnaireRoot"

export default {
  name: "QuestionnairePage",
  components: {
    QuestionnaireRoot,
    TheHeader,
    TheFooter
  }
}
</script>

<template>
  <the-header/>
  <div class="main-container">
    <questionnaire-root/>
  </div>
  <the-footer/>
</template>