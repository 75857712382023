<script>
import { useStore } from 'vuex'
import { onBeforeMount, defineAsyncComponent, shallowRef, computed } from "vue"
import { getEverflow, getJornayaLeadIdToken, getTrustedForm } from "@/util/getters"
import { VeProgress } from 'vue-ellipse-progress'

const QuestionnaireSteps = {
  DoYouHaveLifeInsurance: {
    component: shallowRef(defineAsyncComponent(() => import('./steps/DoYouHaveLifeInsurance')))
  },
  HaveYouUsedTobacco: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/HaveYouUsedTobacco')))
  },
  MarriageStatus: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/MarriageStatus')))
  },
  ChildrenStatus: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/ChildrenStatus')))
  },
  DateOfBirth: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/DateOfBirth')))
  },
  ReasonForLifeInsurance: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/ReasonForLifeInsurance')))
  },
  Height: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Height')))
  },
  Weight: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Weight')))
  },
  Treated_Or_Prescribed_Last_Five_Years: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Treated_Or_Prescribed_Last_Five_Years')))
  },
  HospitalizedDueToAnxiety: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/HospitalizedDueToAnxiety')))
  },
  IsMedicalConditionListed: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/IsMedicalConditionListed')))
  },
  EmploymentStatus: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/EmploymentStatus')))
  },
  Zip: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Zip')))
  },
  Name: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Name')))
  },
  Email: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Email')))
  },
  Phone: {
    component: shallowRef(defineAsyncComponent(() => import ('./steps/Phone')))
  },
  ThankYou: {
    component: shallowRef(defineAsyncComponent(() => import ('../shared/ThankYou')))
  }
}

export default {
  name: "QuestionnaireRoot",

  components: {
    VeProgress
  },

  setup() {
    const store = useStore()
    const currentStep = computed(() => store.state.currentStep)
    const currentProgress = computed(() => store.state.progress)
    const isLoading = computed(() => store.state.loading)

    const goToNextStep = (step) => {
      store.commit('SET_IS_LOADING', true)
      store.dispatch('questionnaire/goToNextStep', step)
    }

    onBeforeMount(() => {
      //get jornaya lead id
      window.loadJornayaLeadId('807f6757-b597-40d4-e090-9aa3235e89c0')
      getJornayaLeadIdToken().then(jornaya_id => {
        store.commit('SET_FIELD', {field: 'jornayaId', value: jornaya_id})
      })

      //get everflow
      getEverflow().then((ef_transaction_id) => {
        store.commit('SET_FIELD', {field: 'ef_transaction_id', value: ef_transaction_id})
      })

      //set trusted form
      getTrustedForm().then(({trustedFormUrl, trustedFormId}) => {
        const trusted = [
          {field: 'trustedFormId', value: trustedFormId},
          {field: 'trustedFormUrl', value: trustedFormUrl}
        ]
        store.commit('SET_FIELDS', trusted)
      })

      //set url param fields
      store.commit('SET_FIELDS_FROM_URL')

      goToNextStep('StartPath')
    })

    return {
      isLoading,
      currentProgress,
      currentStep,
      QuestionnaireSteps,
      goToNextStep
    }
  }
}
</script>

<template>
  <div class="section-container">
    <div class="wrapper question-root-wrapper">
      <div class="progress-container">
        <ve-progress
          :progress="currentProgress"
          :color="'#007640'"
          :loading="isLoading"
          :fontSize="'24px'"
          :fontColor="'#007640'"
          :size="180"
          emptyColor="#fff"
        >
          <template #legend>
            <span class="progress-percent">%</span>
          </template>
          <template #legend-caption>
            <p class="progress-caption">{{isLoading ? 'Loading...' : 'Completed'}}</p>
          </template>
        </ve-progress>
      </div>
      <div class="inner-wrapper question-root-inner-wrapper">
        <transition name="component-fade" mode="out-in" :duration="800">
          <component
            :is="QuestionnaireSteps[currentStep].component.value"
            @next-step="goToNextStep"
          >
          </component>
        </transition>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/styles/component_styles/questionnaire/questionnaire-root.scss';
</style>